import React, { useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import useAuth from 'src/providers/W3Provider/useAuth';

const Wallet = () => {
  const { authStatus } = useAuth();
  const [loggingIn, setLoggingIn] = useState(false); // State to determine if the user has initiated the login

  const handleLoginClick = (openConnectModalFunction) => {
    setLoggingIn(true);
    openConnectModalFunction();
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      {authStatus !== 'authenticated' && !loggingIn && (
        <ConnectButton.Custom>
          {({ openConnectModal, mounted, authenticationStatus }) => {
            const ready = mounted && authenticationStatus !== 'loading';
            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                <button
                  className="bg-white text-black px-4 py-2 rounded-md font-semibold cursor-pointer hover:scale-[1.03] ease-in duration-100"
                  onClick={() => handleLoginClick(openConnectModal)}
                  type="button"
                >
                  Log in
                </button>
              </div>
            );
          }}
        </ConnectButton.Custom>
      )}
      <ConnectButton
        label="Sign up"
        chainStatus="none"
        accountStatus="avatar"
      />
    </div>
  );
};

export default Wallet;
